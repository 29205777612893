import Helmet from "react-helmet"
import React from "react"
import CompetitorBM from "src/components/competitorBM"
import HomepageLayout from "src/layouts/homepage"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

const compData = import("./data/data.json");

const compBrowsers = ['chrome','firefox','ie','edge','other'];

export default function CompetitorSpaNT() {

  let disclosure = null;
if(isChrome()){
  disclosure = <CtaDisclosureNewtab  data={compData}></CtaDisclosureNewtab>;
}
else{
  disclosure = <CtaDisclosureNewtab  data={compData}></CtaDisclosureNewtab>;
}

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
         .footerlinkssingle-module--br {
           position: relative;
           bottom: 0;
           left: 0;
           transform: none;
           color: #999;
           line-height: 150%;
         }

         .footerlinkssingle-module--br a {
           color: #999;
         }

         #ctadisclosurenewtab-module--ctadisclaimer {
           width: 60%;
           text-align: center;
           max-width: 700px;
           line-height: 150%;
         }
    `}
    </style>
    <title>PDFtoDOC New Tab - frompdftodoc.com</title></Helmet>
    <section>
      <CompetitorBM data={compData} browsers={compBrowsers}>
        {disclosure}
      </CompetitorBM>
      </section>
    </HomepageLayout>
  )
}

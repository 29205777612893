import React from "react"
import Styles from "./css/footerlinkssingle.module.scss"

class Footer extends React.Component {
  constructor(props){
    super(props);
      if(props.data){
      this.state = {
        data: props.data
      }
    }
  }
  render() {
    return (
      <div className ={Styles.br}>
        Tightrope Interactive
          | <a href="/terms-of-service/" target="_blank">Terms</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/uninstall/" target="_blank">Uninstall</a> | <a href="/unsubscribe/" target="_blank">Unsubscribe</a> | <a href="/contact-us/" target="_blank">Contact</a> <br />
          All trademarks are property of their respective owners | <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
      </div>
    )
  }
}

export default Footer
